// Here you can add other styles
.login-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
}
.main-logo-div {
  display: flex;
  align-self: center;
  margin-top: 6vh;
  margin-bottom: 80px;
}
.text-center {
  font-size: 1rem;
}
.sidebar-logo img {
  width: 80%;
  height: auto;
  object-fit: contain;
  display: block;
  // margin-right: auto;
  // margin-left: auto;
  margin-left: 10px;
}

#comodo-logo {
  float: right;
  padding-top: 2vh;
  position: relative;
}

.header-logo img {
  width: 100%;
  height: auto;
  max-height: 100px;
  object-fit: contain;
}

.bazin-widget {
  margin: 10px 10px;
}

.color-success {
  // background-color: #369a43;
  background-color: #369a96;
}

.color-warning {
  background-color: #f7ab36;
}

.color-danger {
  background-color: #bfdfef;
}
.color-danger2 {
  background-color: #e55353;
}

.color-dark {
  background-color: #9a9ea3;
}

.color-dark-err {
  background-color: #1f1f20;
}

.text-white {
  padding: 3px;
  font-size: 1rem;
  color: white;
}

.text-black {
  padding: 3px;
  font-size: 1rem;
  color: #232323;
}

.hranitoare-widget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom-widgets-div {
  font-size: 1rem;
}

.custom-widgets-div span {
  font-size: 100%;
}

.min-widget-fix {
  padding: 0;
  border: none;
}

.blm {
  background-color: #14acd8;
  color: #ffffff;
}

.color-lightblue {
  background-color: #14acd8;
}

.bazin-activ {
  // background-color: #369a43;
  background-color: #369a96;
}

.bazin-inactiv {
  background-color: #9a9ea3;
}

.bazin-raport-dropdown {
  font-size: 1.3rem;
}

.nav-pills .nav-link.active {
  background-color: #14acd8;
  color: white;
}

// .nav-pills {
//   font-size: 1rem;
// }

a {
  color: #354a53;
}
a:hover {
  color: #354a53;
}

.btn-primary {
  background-color: #14acd8;
  border-color: #14acd8;
  // font-size: 1rem;
}

.btn-primary:hover {
  background-color: #14acd8;
  border-color: #14acd8;
  // font-size: 1rem;
}

.btn-danger {
  background-color: #e55353;
  border-color: #e55353;
  // font-size: 1rem;
}

.btn-danger:hover {
  background-color: #e55353;
  border-color: #e55353;
  // font-size: 1rem;
}

.btn-get-res {
  margin-bottom: 12px;
}

.card-header {
  background-color: #303c54;
  color: white;
  // font-size: 1rem;
}

.badge-info {
  background-color: transparent;
}

.bg-gradient-primary {
  background: linear-gradient(45deg, #003257 0%, #005aa4 100%) !important;
  border-color: #14acd8 !important;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #14acd8;
}

.bazin-min-div {
  font-size: 1rem;
  color: white;
  margin-bottom: 10px;
}

.val-sen-min {
  border: 1px solid black;
  text-align: center;
  width: 100%;
}

.calendar-select-div {
  display: flex;
  flex-direction: row;
}
.calendar-select {
  display: flex;
  margin-top: 8px;
  margin-right: 1vw;
  margin-bottom: 8px;
}
.calendar-h3 {
  margin-right: 0.5vw;
  margin-bottom: 0;
  line-height: 1.6;
}

#user-profile-picture {
  max-height: 40px;
  max-width: 40px;
}

.too-many-navs {
  border: 1px solid #303c54;
  margin: 4px;
}

#initial-loc-h1 {
  font-size: 2.5rem;
  margin-bottom: 3vh;
}

.jumbo-loc {
  font-size: 1.5rem;
}
.jumbo-loc-h1 {
  font-size: 2.5rem;
  margin-top: 1vh;
  margin-bottom: 3vh;
}

.btn-custom {
  font-size: 15px;
  background-color: #14acd8;
  color: white;
}

.c-header-toggler-icon {
  height: 2rem;
}

@media screen and (max-width: 575px) {
  .mobile-font {
    font-size: 0.7rem !important;
    font-weight: bold;
  }
  .calendar-select-div {
    flex-direction: column;
  }
  .calendar-select {
    flex-direction: row;
  }
}
